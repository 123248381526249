<template>
	<Requests :token="token" :key="my_key" :request_type="request_type" :url="my_url" :input_data="[]" :notification_type="notification_type" @response="getResponse" v-if="operation=='get-data'" />
	<Requests :token="token" :key="my_key3" :request_type="request_type" :url="my_url3" :input_data="[]" :notification_type="notification_type3" @response="getResponse" v-if="operation=='update-data'" />
	<ul>
		  <li class="row" v-for="(my_product, i) in my_approved_products" :key="i"><br>
			<div class="col s12">
				<div class="col s12 m12 l8" v-if="my_product.read_or_not==1">{{ my_product.details.substring(0, 102) }}</div>
				<div class="col s12 m12 l8" v-else><b>{{ my_product.details.substring(0, 102) }}</b></div>
				
				<div class="col s12 m12 l3" v-if="my_product.read_or_not==1">{{ my_product.datetime }}</div>
				<div class="col s12 m12 l3" v-else><b>{{ my_product.datetime }}</b></div>
				<div class="col s12 m12 l1">
					<a href="#" class="btn-floating btn-medium blue" v-if="my_product.read_or_not==1"><i class="material-icons white-text text-darken-4">drafts</i></a>
					<a href="#" class="btn-floating btn-medium red hoverable" v-on:click="markAsRead(my_product.data_id, my_product.details)"  v-else><i class="material-icons white-text text-darken-4">email</i></a>
				</div>
			</div>
		  </li>
	</ul>
	
	<div id="read_notification" class="modal modal-fixed-footer">
		<div class="modal-content">
		  <a href="#!" class="modal-close right"><i class="material-icons red-text text-darken-4">close</i></a>
		  <h4>{{ languages[0] }}</h4><hr>
		  {{ read_notification }}
		</div>
	</div>
</template>

<script>
// @ is an alias to /src)
import router from '@/router'
import DownloadReports from '@/components/DownloadReports.vue'
import Requests from '@/components/Requests.vue'
import MFiles from '@/assets/js/MFiles'
import Nav from '@/components/Nav.vue'
import MySideNav from '@/components/MySideNav.vue'
import AppFooter from '@/components/AppFooter.vue'
import countries from '@/configs/countries.json';
import comesa_countries from '@/configs/comesa_countries.json';
import language from '@/configs/language.json';

import axios from 'axios'
import Configs from '@/assets/js/Configs'
import apis from '@/configs/apis.json';
import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'


export default {
	name: 'RoleManagement',
	components: {
		Nav,
		MySideNav,
		AppFooter,
		Requests,
		DownloadReports
	},
	data() {
		
        return {
			db: null,
			read_notification: "",
			application_type: "",
			tpin_or_name: "",
			from_date: "",
			to_date: "",
			filter_country: "",
			filter_town: "",
			approved_class: "btn btn-medium green darken-4 hoverable",
			pending_class: "btn btn-medium green darken-2 hoverable z-depth-3",
			rejected_class: "btn btn-medium green darken-4 hoverable",
			
			product_selected: null,
			product_name: null,
			product_code: null,
			current_key: 0,
			token: null,
			my_url: null,
			my_url2: null,
			my_url3: null,
			my_roles: null,
			
			confirmation_heading: "",
			deactivate_text: "",
			add_product_back: true,
			add_product_next: false,
			add_product_save: false,
			
			pre_loaded: true,
			data_loading: true,
			data_loaded: true,
			
			modal: false,
			id: "", 
			product_name: "", 
			product: "",
			role_geo_limit: "",
			role_org_limit: "",
			web_display: "",
			role_status: "",
			
			x: "",
			my_key: 0,
			my_key2: 0,
			my_key3: 0,
			pageno: 1,
			records_per_page: 10,
			no_of_pages: 0,
			num_rows_on_last_page: 0,
			notification_type: "none",
			notification_type3: "modal",
			request_type: "GET",
			request_type3: "POST",
			operation: 'get-data',
			
			status: "",
			my_operation: "",
			comments: "",
			to_status: "",
			
			pre_response: "",
			during_response: "",
			post_response: false,

			product_name_err: "",
			product_description_err: "",
			status_err: "",
			
			product_name_err: "",
			role_geo_limit_err: "",
			town_err: "",
			role_org_limit_err: "",
			product_err: "",
			role_status_err: "",
			phone_err: "",
			address1_err: "",
			web_display_err: "",
			users_empty_err: "",
			
			my_approved_products: [],
			product_err: "",
			mycountries: null,
			countries: null,
			filtered_countries: [],
			filter_obj: [],
			menu_status: "open",
			side_bar: "",
			main_area: 'col m8 l9 xl10 s12',
			side_bar_style: 'border-right: 0.1pt solid #cfcfcf; border-bottom: 0.1pt solid #cfcfcf;',
			
			mylang: 'en',
			languages: [],
        };
    },
    methods: {
		async initFunctions(){
			let mylang = await this.db.getStorageItem("mylang");
			if(mylang=="" || mylang==null || mylang===undefined)
			{
				mylang = 'en';
			}
			this.mylang = mylang;
			this.languages = language[mylang].comp__dashboardnotifications;
		},	
		async initData(){			
			this.initFunctions();
			let num_keys;
			let group_name;
			let group_name_small;
			
			let product_management_options_obj = await this.db.getStorageItem("product_management_options");
			if(product_management_options_obj != null && product_management_options_obj != "")
			{
				
			}
			else
			{
				product_management_options_obj = {};
				product_management_options_obj["product_selected"] = false;
				product_management_options_obj["product_name"] = "";
				product_management_options_obj["product_code"] = "";
			}
		
			let my_roles_var = await this.db.getStorageItem("my_products");
			if(my_roles_var != null && my_roles_var != "")
			{
				
			}
			else
			{
				my_roles_var = [];
			}
			
			this.product_selected = product_management_options_obj.product_selected;
			this.product_name = product_management_options_obj.product_name;
			this.product_code = product_management_options_obj.product_code;
			this.current_key = num_keys;
			this.token = await this.db.getStorageItem("token");
			this.my_url = this.setURL();
			this.my_url2 = this.setURL2();
			this.my_roles = my_roles_var;
		},	
		async markNotificationAsRead(data_id){
			let token = await this.db.getStorageItem("token");
			
			let my_params = [];
			
			let axios_headers = {
				  'Content-Type': 'multipart/form-data;charset=UTF-8 boundary=' + Math.random().toString().substr(2),
				  "Access-Control-Allow-Origin": "*",
				  "token": token,
			};
			
			this.data_loaded = false;
			this.data_loading = true;
			
			let myConfigs = new Configs();
			let my_url = await myConfigs.apiUrl()+'/get/users/notifications/mark_as_read/data_id:like:'+data_id;
			
			//console.log(my_url);
			
			axios({method: 'GET', url: my_url, headers: axios_headers, params: my_params})
			.then(response => {
			
				//console.log("notification update response: ");
				//console.log(response);
				
				let my_res = response;
				
				if (!('exception' in my_res))
				{
					if('output' in my_res.data)
					{
						this.operation = 'get-data';
						this.getData('Pending');
					}
					else
					{
						//console.log("response: "+JSON.stringify(response));
					}
					
					this.data_loaded = true;
					this.data_loading = false;
					
				}
				else
				{
					/**
					if('message' in my_res.data)
					{
						this.user_role_err = my_res.data.message;
					}
					else
					{
						this.user_role_err = JSON.stringify(my_res.data);
					}
					**/
					this.data_loaded = true;
					this.data_loading = false;
					
				}
			})
			.catch(error => {
			
				//console.log("error: "+JSON.stringify(error));
				
				if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
				{
					if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
					{
						if('message' in error.data)
						{
							this.user_role_err = error.data.message;
						}
						else
						{
							this.user_role_err = JSON.stringify(error.data);
						}
					}
					else
					{
						this.user_role_err = error.data;
					}
				}
				else
				{
					this.user_role_err = error;
				}
				
				this.data_loaded = true;
				this.data_loading = false;
			});
		},
		markAsRead(data_id, notification) {
		  this.read_notification = notification;
		  let modal_id = document.getElementById("read_notification");
		  let instance = M.Modal.init(modal_id, {});
		  instance.open();

		  setTimeout(() => {
			this.markNotificationAsRead(data_id);
		  }, 7000);
		},
		async menuStatus(status=''){
			let myConfigs = new Configs();
			let styles = await myConfigs.menuStyles(status);
			
			this.menu_status = styles.menu_status;
			this.side_bar = styles.side_bar;
			this.main_area = styles.main_area;
			this.side_bar_style = styles.side_bar_style;
			this.main_area_height = styles.main_area_height;
			this.main_area_width = styles.main_area_width;
		},
		setRoleStatusForGetReq(status){
			if(status.toString().toLowerCase()=="pending")
			{
				this.approved_class = "btn btn-medium green darken-4 hoverable";
				this.pending_class = "btn btn-medium green darken-2 hoverable z-depth-3";
				this.rejected_class = "btn btn-medium green darken-4 hoverable";
			}
			else if(status.toString().toLowerCase()=="approved")
			{
				this.approved_class = "btn btn-medium green darken-2 hoverable z-depth-3";
				this.pending_class = "btn btn-medium green darken-4 hoverable";
				this.rejected_class = "btn btn-medium green darken-4 hoverable";
			}
			else if(status.toString().toLowerCase()=="rejected")
			{
				this.approved_class = "btn btn-medium green darken-4 hoverable";
				this.pending_class = "btn btn-medium green darken-4 hoverable";
				this.rejected_class = "btn btn-medium green darken-2 hoverable z-depth-3";
			}
			
			this.status = status;
			this.getData();
			this.my_key2 = this.my_key2+1;
			this.my_url2 = this.setURL2();
		},
		setRoleStatusOnly(status){
			if(status.toString().toLowerCase()=="pending")
			{
				this.approved_class = "btn btn-medium green darken-4 hoverable";
				this.pending_class = "btn btn-medium green darken-2 hoverable z-depth-3";
				this.rejected_class = "btn btn-medium green darken-4 hoverable";
			}
			else if(status.toString().toLowerCase()=="approved")
			{
				this.approved_class = "btn btn-medium green darken-2 hoverable z-depth-3";
				this.pending_class = "btn btn-medium green darken-4 hoverable";
				this.rejected_class = "btn btn-medium green darken-4 hoverable";
			}
			else if(status.toString().toLowerCase()=="rejected")
			{
				this.approved_class = "btn btn-medium green darken-4 hoverable";
				this.pending_class = "btn btn-medium green darken-4 hoverable";
				this.rejected_class = "btn btn-medium green darken-2 hoverable z-depth-3";
			}
			
			this.status = status;
		},
		currentDate() {
			var d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;

			return [year, month, day].join('-');
		},
		setFromDate(){
			var options = {
				format: "yyyy-mm-dd"
			};
			var elems = document.getElementById("from_date");
			var instance = M.Datepicker.init(elems, options);
			this.from_date = document.getElementById("from_date").value;
		},
		setToDate(){
			var options = {
				format: "yyyy-mm-dd"
			};
			var elems = document.getElementById("to_date");
			var instance = M.Datepicker.init(elems, options);
			this.to_date = document.getElementById("to_date").value;
		},
		filterData(pageno='1'){
		
			if(pageno==='+1')
			{
				this.pageno = this.pageno+1;
			}
			else if(pageno==='-1')
			{
				if(this.pageno>0)
				{
					this.pageno = this.pageno-1;
				}
			}
			else
			{
				this.pageno = parseInt(pageno);
			}
			
			let filter_obj = [];
			filter_obj.push(["operation",this.my_operation.trim()]);
			filter_obj.push(["status",this.status.trim()]);
			this.my_key = this.my_key+1;
			this.my_url = this.setURL();
			this.my_url2 = this.setURL2();
			
			//console.log(this.my_key);
			
			this.filter_obj = filter_obj;
			this.getData(filter_obj);
		},
		currentDate() {
			var d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;

			return [year, month, day].join('-');
		},
		checkFilterCol(cur_filter1){
			let out;
			if(cur_filter1[1] == "" || cur_filter1[1] == null)
			{
				out = false;
			}
			else
			{
				out = true;
			}
			
			return out;
		},
		range(startAt = 0) {
			return [...Array(this.no_of_pages).keys()].map(i => i + startAt);
		},
		getClass(a){
			let list_page = a+1;
			let my_class;
			if(list_page==this.pageno)
			{
				my_class="active";
			}
			else
			{
				my_class="waves-effect";
			}
			return my_class;
		},
		async getResponse(response){
			this.response = response;
			console.log("notifications response: ");
			console.log(response);
			if('data' in response)
			{
				if('output' in response.data)
				{
					if(this.operation == 'update-data')
					{
						this.operation = 'get-data';
						this.getData('Pending');
					}
					else
					{
						this.my_approved_products = response.data.output;
						this.records_per_page = response.data.records_per_page;
						this.no_of_pages = response.data.no_of_pages;
						if(response.data.num_rows_on_last_page != null && response.data.num_rows_on_last_page != "")
						{
							this.num_rows_on_last_page = response.data.num_rows_on_last_page;
						}
					}
						
				}
			}
		},
		setURL(pageno='1'){
		
			let my_filter = "";
			let cur_filter;
			let cur_filter1;
			
			let filter_obj = this.filter_obj;
			
			/*
			if(Array.isArray(filter_obj))
			{
				let w=0;
				let w1=0;
				let c=0;
				let c1=0;
				let end_bit;
				let filter_check;
				let filter_check1;
				
				for(w1=0; w1<filter_obj.length; w1++)
				{
					cur_filter1 = filter_obj[w1];
					filter_check1 = this.checkFilterCol(cur_filter1);
					
					if(filter_check1==true)
					{
						c1=c1+1;
					}
				}
				
				if(c1>0)
				{
					my_filter += ":AND";
				}
				
				for(w=0; w<filter_obj.length; w++)
				{
					cur_filter = filter_obj[w];
					filter_check = this.checkFilterCol(cur_filter);
					
					if(filter_check==true)
					{
						c=c+1;
						if(c==c1)
						{
							end_bit = "";
						}
						else
						{
							end_bit = ":AND";
						}
						my_filter += "/"+cur_filter[0]+":like:%"+cur_filter[1]+"%"+end_bit;
					}
				}
			}*/
			
			my_filter = my_filter+"/pageno:=:"+this.pageno+"/records_per_page:=:"+this.records_per_page;
			
			let my_url = '/get/users/notifications'+my_filter;
			
			console.log(my_url);
			
			return my_url;
		},
		setURL2(){
		
			let my_filter = "";
			let cur_filter;
			let cur_filter1;
			
			let filter_obj = this.filter_obj;
			
			if(Array.isArray(filter_obj))
			{
				
				let w=0;
				let w1=0;
				let c=0;
				let c1=0;
				let end_bit;
				let filter_check;
				let filter_check1;
				
				for(w1=0; w1<filter_obj.length; w1++)
				{
					cur_filter1 = filter_obj[w1];
					filter_check1 = this.checkFilterCol(cur_filter1);
					
					if(filter_check1==true)
					{
						c1=c1+1;
					}
				}
				
				if(c1>0)
				{
					my_filter += ":AND";
				}
				
				for(w=0; w<filter_obj.length; w++)
				{
					
					cur_filter = filter_obj[w];
					filter_check = this.checkFilterCol(cur_filter);
					
					if(filter_check==true)
					{
						c=c+1;
						if(c==c1)
						{
							end_bit = "";
						}
						else
						{
							end_bit = ":AND";
						}
						my_filter += "/"+cur_filter[0]+":like:%"+cur_filter[1]+"%"+end_bit;
					}
				}
			}
			
			my_filter = my_filter+"/pageno:=:1/records_per_page:=:*";
			
			let my_url = '/get/users/notifications'+my_filter;
			
			//console.log(my_url);
			
			return my_url;
		},
		async getData(filter_obj=null){
			
			if(filter_obj==null)
			{
				this.filter_obj = [];
			}
			else
			{
				this.filter_obj = filter_obj;
			}
			
			let token = await this.db.getStorageItem("token");
			this.token = token;
			
			this.my_url = this.setURL(this.pageno);
			this.my_key = this.my_key+1;
		},
		async changeRoleStatus(arr_key,status){
			let token = await this.db.getStorageItem("token");
			
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			let my_product;
			let my_roles;
			if((arr_key != "" && arr_key != null) || arr_key === 0)
			{
				my_roles = await this.db.getStorageItem("my_products");
				my_product = my_roles[arr_key];
				
				//console.log(JSON.stringify(my_roles[arr_key]));
				//console.log(arr_key);
				
				my_roles[arr_key]['role_status'] = status;
				
				this.data_loaded = false;
				this.data_loading = true;
				
				let formData = new FormData();
				formData.append('role_status',status);
				
				//console.log(formData);
				//axiosConfig
				
				let myConfigs = new Configs();
				axios.defaults.baseURL = await myConfigs.apiUrl();
				axios.defaults.headers.token = token;
				axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8 boundary=' + Math.random().toString().substr(2);
				
				axios.post('/edit/users/roles/product:=:'+my_product.product, formData)
				.then(response => {
					
					let my_res = response;
					
					if (!('exception' in my_res))
					{
						if('output' in my_res.data)
						{
							this.db.setStorageItem("my_products", my_roles);
							
							this.my_roles = my_roles;
							this.getData(this.status);
						}
						else
						{
							//console.log("response: "+JSON.stringify(response));
						}
						
							//console.log("response: "+JSON.stringify(response));
							
						this.data_loaded = true;
						this.data_loading = false;
					}
					else
					{
						this.data_loaded = true;
						this.data_loading = false;
					}
				})
				.catch(error => {
				
					//console.log("error: "+JSON.stringify(error));
					
					if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
					{
						if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
						{
							if('message' in error.data)
							{
								this.product_err = error.data.message;
							}
							else
							{
								this.product_err = JSON.stringify(error.data);
							}
						}
						else
						{
							this.product_err = error.data;
						}
					}
					else
					{
						this.product_err = error;
					}
					
					this.data_loaded = true;
					this.data_loading = false;
				});
			}
			else
			{
				this.deactivate_text = "User data not found. Please refresh the page and try again.";
			}
		},
		async editRole(){
			let token = await this.db.getStorageItem("token");
			
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			
			let my_product;
			let my_roles;
			
			this.data_loaded = false;
			this.data_loading = true;
			
			let formData = new FormData();
			formData.append('product_name',this.product_name);
			formData.append('role_geo_limit',this.role_geo_limit);
			formData.append('role_org_limit',this.role_org_limit);
			formData.append('product',this.product);
			formData.append('web_display',this.web_display);
			
			//console.log(formData);
			//axiosConfig
			
			let myConfigs = new Configs();
			axios.defaults.baseURL = await myConfigs.apiUrl();
			axios.defaults.headers.token = token;
			axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8 boundary=' + Math.random().toString().substr(2);
			
			axios.post('/edit/users/roles/product:=:'+this.product, formData)
			.then(response => {
				
				let my_res = response;
				
				if (!('exception' in my_res))
				{
					if('output' in my_res.data)
					{
						this.db.setStorageItem("my_products", my_roles);
						
						this.my_roles = my_roles;
						this.getData(this.status);
					}
					else
					{
						//console.log("response: "+JSON.stringify(response));
					}
					
						//console.log("response: "+JSON.stringify(response));
						
					this.data_loaded = true;
					this.data_loading = false;
				}
				else
				{
					this.data_loaded = true;
					this.data_loading = false;
				}
			})
			.catch(error => {
			
				//console.log("response: "+JSON.stringify(response));
				
				if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
				{
					if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
					{
						if('message' in error.data)
						{
							this.product_err = error.data.message;
						}
						else
						{
							this.product_err = JSON.stringify(error.data);
						}
					}
					else
					{
						this.product_err = error.data;
					}
				}
				else
				{
					this.product_err = error;
				}
				
				this.data_loaded = true;
				this.data_loading = false;
			});
		},
		async change_product_status(id, status){
			let my_roles = await this.db.getStorageItem("my_products");
			
			if(my_roles != null && my_roles !="")
			{
				
				this.to_status = status;
				this.id = id;
				this.product_name = my_roles[id].product_name;
				this.role_geo_limit = my_roles[id].role_geo_limit;
				this.product = my_roles[id].product;
				this.role_org_limit = my_roles[id].role_org_limit;
				this.web_display = my_roles[id].web_display;
				
				if(status.toString().toLowerCase()=="pending")
				{
					this.confirmation_heading = "Set User Account Status to Pending";
					this.deactivate_text = "Are you sure you want to set the account belonging to "+my_roles[id].product_name+" "+my_roles[id].role_geo_limit+" of web_display address "+my_roles[id].web_display+" to Pending?";
				}
				else if(status.toString().toLowerCase()=="approved")
				{
					this.confirmation_heading = "Set User Account Status to Approved";
					this.deactivate_text = "Are you sure you want to Activate the account belonging to "+my_roles[id].product_name+" "+my_roles[id].role_geo_limit+" of web_display address "+my_roles[id].web_display+"?";
				}
				else if(status.toString().toLowerCase()=="rejected")
				{
					this.confirmation_heading = "Set User Account Status to Rejected";
					this.deactivate_text = "Are you sure you want to Deactivate the account belonging to "+my_roles[id].product_name+" "+my_roles[id].role_geo_limit+" of web_display address "+my_roles[id].web_display+"?";
				}
			}
			else
			{
				this.deactivate_text = "Sorry, no user data has been found. Try to refresh the page and try again.";
			}
			
			
			let modal_id = document.getElementById("change_role_status_modal");
			let instance = M.Modal.init(modal_id, {});
			instance.open();
			//console.log(status);
		},
		async queryUserAccount(id, status){
			
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			
			
			
			let my_roles = await this.db.getStorageItem("my_products");
			if(my_roles != null && my_roles !="")
			{
				
				this.to_status = status;
				this.id = id;
				this.product_name = my_roles[id].product_name;
				this.role_geo_limit = my_roles[id].role_geo_limit;
				this.product = my_roles[id].product;
				this.role_org_limit = my_roles[id].role_org_limit;
				this.web_display = my_roles[id].web_display;
				
				this.confirmation_heading = "Query User Account";
				this.deactivate_text = "By Querying it, please note that you are setting the account belonging to "+my_roles[id].product_name+" "+my_roles[id].role_geo_limit+" of web_display address "+my_roles[id].web_display+" to Pending. Do you accept?";
			}
			else
			{
				this.deactivate_text = "Sorry, no user data has been found. Try to refresh the page and try again.";
			}
			let modal_id = document.getElementById("query_account_modal");
			let instance = M.Modal.init(modal_id, {});
			instance.open();
		},
		async view_product(id){
			
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			let my_roles = await this.db.getStorageItem("my_products");
			if(my_roles == null || my_roles=="")
			{
				//alert("Nothing to do");
			}
			else
			{
				this.product_name = my_roles[id].product_name;
				this.role_geo_limit = my_roles[id].role_geo_limit;
				this.product = my_roles[id].product;
				this.role_org_limit = my_roles[id].role_org_limit;
				this.web_display = my_roles[id].web_display;
			}
			let modal_id = document.getElementById("view_modal");
			let instance = M.Modal.init(modal_id, {});
			instance.open();
		},
		async edit_product(id){
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			
			let my_roles = await this.db.getStorageItem("my_products");
			if(my_roles == null || my_roles=="")
			{
				//alert("Nothing to do");
			}
			else
			{
				this.product_name = my_roles[id].product_name;
				this.role_geo_limit = my_roles[id].role_geo_limit;
				this.product = my_roles[id].product;
				this.role_org_limit = my_roles[id].role_org_limit;
				this.web_display = my_roles[id].web_display;
			}
			let modal_id = document.getElementById("edit_modal");
			let instance = M.Modal.init(modal_id, {});
			instance.open();
			M.updateTextFields();
		},
		query_product(id){
			let modal_id = document.getElementById("query_account_modal");
			let instance = M.Modal.init(modal_id, {});
			instance.open();
		},
		async save_product(){
			let token = await this.db.getStorageItem("token");
			
			let product_management_options_obj;
			let group_name;
			let group_name_small;
			
			
			
			
			
			let my_product;
			let my_roles;
			
			this.pre_loaded = false;
			this.data_loading = true;
			this.data_loaded = false;
			
			let formData = new FormData();
			formData.append('product_name',this.product_name);
			formData.append('role_geo_limit',this.role_geo_limit);
			formData.append('role_org_limit',this.role_org_limit);
			formData.append('product',this.product);
			formData.append('web_display',this.web_display);
			
			//console.log(formData);
			//axiosConfig
			
			let myConfigs = new Configs();
			axios.defaults.baseURL = await myConfigs.apiUrl();
			axios.defaults.headers.token = token;
			axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8 boundary=' + Math.random().toString().substr(2);
			
			axios.post('/add/users/roles/register', formData)
			.then(response => {
				
				//console.log("response: "+JSON.stringify(response));
				
				let my_res = response;
				
				if (!('exception' in my_res))
				{
					if('output' in my_res.data)
					{
						this.db.setStorageItem("my_products", my_roles);
						
					}
					else
					{
						//console.log("response: "+JSON.stringify(response));
					}
					
						//console.log("response: "+JSON.stringify(response));
				}
				else
				{
				
				}
				this.product_err = my_res.data.message;
				
				this.pre_loaded = false;
				this.data_loading = false;
				this.data_loaded = true;
				
				this.my_roles = my_roles;
				this.getData(this.status);
				
				setTimeout(function() {
					let modal_id = document.getElementById("confirm_add_product");
					let instance = M.Modal.init(modal_id, {});
					instance.close(); 
				}, 3000);
	  
			})
			.catch(error => {
			
				//console.log("error: "+JSON.stringify(error));
				
				if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
				{
					if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
					{
						if('message' in error.data)
						{
							this.product_err = error.data.message;
						}
						else
						{
							this.product_err = JSON.stringify(error.data);
						}
					}
					else
					{
						this.product_err = error.data;
					}
				}
				else
				{
					this.product_err = error;
				}
				
				this.pre_loaded = false;
				this.data_loading = false;
				this.data_loaded = true;
				
				this.my_roles = my_roles;
				this.getData(this.status);
				
				setTimeout(function() {
					let modal_id = document.getElementById("confirm_add_product");
					let instance = M.Modal.init(modal_id, {});
					instance.close(); 
				}, 3000);
	  
			});
		}
    },
	watch: {
		product_name: function() {
		  if((this.product_name != "" && this.product_name != null) && (this.role_geo_limit != "" && this.role_geo_limit != null) && (this.product != "" && this.product != null) && (this.role_org_limit != "" && this.role_org_limit != null) && (this.web_display != "" && this.web_display != null))
		  {
			this.add_product_back = true;
			this.add_product_next = true;
			this.add_product_save = true;
		  }
		  
		  if(this.product_name != "" && this.product_name != null)
		  {
			this.product_name_err = '';
			this.my_roles_empty_err = '';
		  }
		},
		role_geo_limit: function() {
		  if((this.product_name != "" && this.product_name != null) && (this.role_geo_limit != "" && this.role_geo_limit != null) && (this.product != "" && this.product != null) && (this.role_org_limit != "" && this.role_org_limit != null) && (this.web_display != "" && this.web_display != null))
		  {
			this.add_product_back = true;
			this.add_product_next = true;
			this.add_product_save = true;
		  }
		  
		  if(this.role_geo_limit != "" && this.role_geo_limit != null)
		  {
			this.role_geo_limit_err = '';
		  }
		},
		product: function() {
		  if((this.product_name != "" && this.product_name != null) && (this.role_geo_limit != "" && this.role_geo_limit != null) && (this.product != "" && this.product != null) && (this.role_org_limit != "" && this.role_org_limit != null) && (this.web_display != "" && this.web_display != null))
		  {
			this.add_product_back = true;
			this.add_product_next = true;
			this.add_product_save = true;
		  }
		  
		  if(this.product != "" && this.product != null)
		  {
			this.product_err = '';
		  }
		},
		role_org_limit: function() {
		  if((this.product_name != "" && this.product_name != null) && (this.role_geo_limit != "" && this.role_geo_limit != null) && (this.product != "" && this.product != null) && (this.role_org_limit != "" && this.role_org_limit != null) && (this.web_display != "" && this.web_display != null))
		  {
			this.add_product_back = true;
			this.add_product_next = true;
			this.add_product_save = true;
		  }
		  
		  if(this.role_org_limit != "" && this.role_org_limit != null)
		  {
			this.role_org_limit_err = '';
		  }
		},
		web_display: function() {
		  if((this.product_name != "" && this.product_name != null) && (this.role_geo_limit != "" && this.role_geo_limit != null) && (this.product != "" && this.product != null) && (this.role_org_limit != "" && this.role_org_limit != null) && (this.web_display != "" && this.web_display != null))
		  {
			this.add_product_back = true;
			this.add_product_next = true;
			this.add_product_save = true;
		  }
		  
		  if(this.web_display != "" && this.web_display != null)
		  {
			this.web_display_err = '';
		  }
		}
	},
	async created() { this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }, async mounted () { let token = await this.db.getStorageItem("token"); if(token==null || token==""){router.push('/login');} else { this.initData(), this.getData(),this.menuStatus(), M.AutoInit() } }
}
</script>