<template>
	<div class="spinner" v-if="notification_type=='modal' && during_response"></div>
	<img src="@/../public/img/success.png" class="status_img" v-if="notification_type=='modal' && my_response_status==true && post_response && success==true && fail==false">
	<img src="@/../public/img/fail.png" class="status_img" v-if="notification_type=='modal' && my_response_status==true && post_response && success==false && fail==true">

	<div class="row" v-else-if="notification_type=='line'">
		<div class="col s12">
			<div class="col s12">
				<div class="progress" v-if="pre_response">
					<div class="determinate" style="width: 1%"></div>
				</div>
				<div class="progress" v-if="during_response">
					<div class="indeterminate"></div>
				</div>
				<div class="progress" v-if="post_response">
					<div class="determinate" style="width: 100%"></div>
				</div>
				<div class="col s12 white lighten-4" v-if="error_response">
					<p><b>{{ response_text }}</b></p>
				</div>  
			</div>
		</div>
	</div>
	
	<div class="row" v-else-if="notification_type=='circle'">
		<div class="col s12">
			<div class="col s12">
					<div class="col s12 white lighten-4" v-if="during_response">
						<div class="col s12">&nbsp;</div>
						<div class="col s12">
							<div class="preloader-wrapper big active">
								<div class="spinner-layer spinner-blue-only">
									<div class="circle-clipper left">
										<div class="circle"></div>
									</div>
									<div class="gap-patch">
										<div class="circle"></div>
									</div>
									<div class="circle-clipper right">
										<div class="circle"></div>
									</div>
								</div>
							</div><br><br><br><br>
						</div>
					</div>
					<div class="col s12 white lighten-4" v-if="post_response">
						<p>&nbsp;<br><b>{{ response_text }}</b><br><br><br><br></p>
					</div>
			</div>
		</div>
	</div>
	
	<span v-else></span>
</template>

<script>
// @ is an alias to /src
import router from '@/router'

import axios from 'axios'

import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'


import Configs from '@/assets/js/Configs'
import apis from '@/configs/apis.json';
import countries from '@/configs/countries.json';
import comesa_countries from '@/configs/comesa_countries.json';

export default {
	name: 'Requests',
	props: ['input_data','url','token','notification_type','request_type','params','response_status'],
	data() {
		
        return {
			my_response_status: this.response_status,
			my_params: this.params,
			my_request_type: this.request_type,
			user_email: "",
			user_password: "",
			response_text: "",
			pre_response: true,
			during_response: false,
			post_response: false,
			success: false,
			fail: false,
			error_response: false
        };
    },
	mounted (){
		if(this.my_request_type.toLowerCase()=='post')
		{
			M.AutoInit(), this.postData()
		}
		else if(this.my_request_type.toLowerCase()=='get')
		{
			M.AutoInit(), this.getData()
		}
	}, 
	methods: {
		async postData(){
			
			this.success = false;
			this.fail = false;
			
			this.pre_response = false;
			this.during_response = true;
			this.post_response = false;
			this.error_response = false;
			
			const formData = new FormData();
			let my_data = this.input_data;
			//console.log(my_data);
			let item_count = my_data.length;
			let a;
			let b;
			let row;
			let keys;
			formData.append('count',item_count);
			for(a=0; a<item_count; a++)
			{
				row = my_data[a];
				keys = Object.keys(row);
				for(b=0; b<keys.length; b++)
				{
					formData.append(keys[b],row[keys[b]]);
				}
			}
			
			
			console.log("testing: ");
			console.log(this.url);
			
			let myConfigs = new Configs();
			axios.defaults.baseURL = await myConfigs.apiUrl();
			//axios.defaults.baseURL = "https://testuiapi.eco.comesa.int/v1/en/json/live";
			axios.defaults.headers.token = this.token;
			axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8 boundary=' + Math.random().toString().substr(2);
			axios.post(this.url, formData)
			.then(response => {
				let my_res = response;
				this.$emit('response', my_res);
				
				
				console.log(my_res);
				
				if (!('exception' in my_res))
				{
					this.pre_response = false;
					this.during_response = false;
					this.error_response = false;
					this.post_response = true;
					this.success = true;
					this.fail = false;
				
					setTimeout(function() {
						this.pre_response = true;
						this.during_response = false;
						this.post_response = false;
						this.error_response = false;
						this.success = false;
					}, 3000);
				}
				else
				{
					if('message' in my_res.data)
					{
						this.response_text = my_res.data.message;
					}
					else
					{
						this.response_text = JSON.stringify(my_res.data);
					}
					
					this.pre_response = false;
					this.during_response = false;
					this.error_response = false;
					this.post_response = true;
					this.success = false;
					this.fail = true;
					
					setTimeout(function() {
						this.pre_response = true;
						this.during_response = false;
						this.post_response = false;
						this.error_response = false;
						this.success = false;
					}, 3000);
				}
				
				
				
			})
			.catch(error => {
				this.$emit('response', error);
				
				console.log("error");
				console.log(error);
				if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
				{
					if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
					{
						if('message' in error.data)
						{
							this.response_text = error.data.message;
						}
						else
						{
							this.response_text = JSON.stringify(error.data);
						}
					}
					else
					{
						this.response_text = error.data;
					}
				}
				else
				{
					this.response_text = error;
				}
				
			
				this.pre_response = false;
				this.during_response = false;
				this.error_response = false;
				this.post_response = true;
				this.success = false;
				this.fail = true;
				
				setTimeout(function() {
					this.pre_response = true;
					this.during_response = false;
					this.post_response = false;
					this.error_response = false;
					this.success = false;
				}, 3000);
			});
		},
		async getData(){
			
			this.pre_response = false;
			this.during_response = true;
			this.post_response = false;
			this.error_response = false;
			this.success = false;
			this.fail = false;
			
			let axios_headers = {
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8 boundary=' + Math.random().toString().substr(2),
				'token': this.token,
			};
			
			
			let myConfigs = new Configs();
			let my_url = await myConfigs.apiUrl()+this.url;
			//let my_url = "https://testuiapi.eco.comesa.int/v1/en/json/live"+this.url;
			
			//console.log(my_url);
			
			axios.get(my_url, {
				headers: axios_headers,
				params: this.my_params
			})
			.then(response => {
				this.$emit('response', response);
				
				let my_res = response;
				
				//console.log(my_res);
				
				if (!('exception' in my_res))
				{
					this.pre_response = false;
					this.during_response = false;
					this.error_response = false;
					this.post_response = true;
					this.success = true;
					this.fail = false;
					
					setTimeout(function() {
						this.pre_response = true;
						this.during_response = false;
						this.post_response = false;
						this.error_response = false;
						this.success = false;
					}, 3000);
				}
				else
				{
					if('message' in my_res.data)
					{
						this.response_text = my_res.data.message;
					}
					else
					{
						this.response_text = JSON.stringify(my_res.data);
					}
					
					this.pre_response = false;
					this.during_response = false;
					this.error_response = false;
					this.post_response = true;
					this.success = false;
					this.fail = true;
					
					setTimeout(function() {
						this.pre_response = true;
						this.during_response = false;
						this.post_response = false;
						this.error_response = false;
						this.success = false;
					}, 3000);
				}
				
				
				
			})
			.catch(error => {
				this.$emit('response', error);
				
				//console.log("error");
				//console.log(error);
				if (typeof error === 'object' && !Array.isArray(error) && error !== null) 
				{
					if (typeof error.data === 'object' && !Array.isArray(error.data) && error.data !== null) 
					{
						if('message' in error.data)
						{
							this.response_text = error.data.message;
						}
						else
						{
							this.response_text = JSON.stringify(error.data);
						}
					}
					else
					{
						this.response_text = error.data;
					}
				}
				else
				{
					this.response_text = error;
				}
				
				this.pre_response = false;
				this.during_response = false;
				this.error_response = false;
				this.post_response = true;
				this.success = false;
				this.fail = true;
				
				setTimeout(function() {
					this.pre_response = true;
					this.during_response = false;
					this.post_response = false;
					this.error_response = false;
					this.success = false;
				}, 3000);
			});
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.status_img {
   position: absolute;
   left: 50%;
   top: 50%;
   height:80px;
   width:80px;
   margin:0px auto;
}

.spinner {
   position: absolute;
   left: 50%;
   top: 50%;
   height:80px;
   width:80px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
</style>
